.OffresAbonnement h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    text-transform: uppercase;
    color: #343434;
}

.OffresAbonnement h1 em {
    font-style: unset;
    color: #87D9D9;
}

.OffresAbonnement p em {
    font-style: unset;
    color: #87D9D9;
}

.OffresAbonnement a {
    color: #87D9D9;
    text-decoration: unset;
}

.OffresAbonnement .slide#slide1 {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    text-align: center;
    width: 100%;
}

.OffresAbonnement .slide#slide1 h1 {
    text-align: right;
    max-width: 500px;
}

.OffresAbonnement .slide#slide1 .image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.OffresAbonnement .slide#slide2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    margin-top: 60px;
}

.OffresAbonnement .slide#slide2 .text-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 650px;
}

.OffresAbonnement .slide#slide2 .text-container h1 {
    transform: translateX(-90px);
    max-width: 500px;
}

.OffresAbonnement .slide#slide3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 140px;
    width: 100%;
    gap: 60px;
}

.OffresAbonnement .slide#slide3 img {
    transform: unset;
    transition: 1s ease-in-out;
}

.OffresAbonnement .slide#slide3 img:hover {
    transform: rotate(360deg);
    transition: 1s ease-in-out;
}

.OffresAbonnement .slide#slide3 .text-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 660px;
}

.OffresAbonnement .slide#slide4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
    gap: 60px;
}

.OffresAbonnement .slide#slide4 .text-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    max-width: 660px;
}

.OffresAbonnement .slide#slide4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
    gap: 60px;
}

.OffresAbonnement .slide#slide5 {
    display: flex;
    flex-direction: row;
    gap: 60px;
}

.OffresAbonnement .slide#slide5 img {
    width: 200px;
}

.OffresAbonnement .slide#slide5 ul {
    list-style: none;
}

.OffresAbonnement .slide#slide5 table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 0.9em;
}

.OffresAbonnement .slide#slide5 table, th, td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

.OffresAbonnement .slide#slide5 thead th {
    background-color: #f2f2f2;
    color: #333;
}

.OffresAbonnement .slide#slide5 tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.OffresAbonnement .slide#slide5 tbody tr:hover {
    background-color: #f1f1f1;
}

.OffresAbonnement .slide#slide5 th, td {
    text-align: center;
}

.OffresAbonnement .slide#slide5 th {
    font-weight: bold;
}

.OffresAbonnement .slide#slide5 .text-container {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    max-width: 660px;
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 800px) {
    .OffresAbonnement .slide#slide1 p {
        width: 90vw;
    }

    .OffresAbonnement .slide#slide1 .image-container {
        width: 90vw;
    }

    .OffresAbonnement .slide#slide1 .image-container img {
        width: 50vw;
    }

    .OffresAbonnement .slide#slide1 h1 {
        font-size: 1.3rem;
    }

    .OffresAbonnement .slide#slide2 img {
        width: 35vw;
    }

    .OffresAbonnement .slide#slide5 img {
        width: 25vw;
        margin-bottom: 20px;
    }

    .OffresAbonnement .slide#slide2 .text-container h1 {
        font-size: 1.3rem;
        transform: unset;
    }

    .OffresAbonnement .slide#slide3 h1 {
        font-size: 1.3rem;
    }

    .OffresAbonnement .slide#slide4 h1 {
        font-size: 1.3rem;
    }

    .OffresAbonnement .slide#slide5 h1 {
        font-size: 1.3rem;
    }

    .OffresAbonnement .slide#slide5 {
        flex-direction: column;
        padding: 0 20px;
        margin-top: 70px;
        gap: unset;
    }

    .OffresAbonnement .slide#slide5 .text-container {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .OffresAbonnement .slide#slide1 {
        align-items: center;
    }

    .OffresAbonnement .slide#slide1 .image-container {
        justify-content: center;
        flex-direction: column-reverse;
        margin-bottom: 20px;
    }

    .OffresAbonnement .slide#slide1 .image-container h1 {
        text-align: center;
    }

    .OffresAbonnement .slide#slide2 {
        flex-direction: column;
        text-align: center;
        width: 90vw;
        padding: 0 20px;
        margin-top: 70px;
        align-items: center;
    }

    .OffresAbonnement .slide#slide3 {
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        width: 90vw;
        padding: 0 20px;
        margin-top: 70px;
        gap: unset;
    }

    .OffresAbonnement .slide#slide3 img {
        transform: rotateZ(-31deg);
        width: 60vw;
    }

    .OffresAbonnement .slide#slide3 img:hover {
        transform: rotateZ(-31deg);
        transition: unset;
    }

    .OffresAbonnement .slide#slide4 {
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        width: 90vw;
        padding: 0 20px;
        margin-top: 70px;
        gap: unset;
    }

    .OffresAbonnement .slide#slide5 img {
        width: 40vw;
    }
}
